<template>
    <div class="interfaceConfig-table">
        <div class="handle-btn">
            <el-button type="primary" size="small" @click="handleSave">
                保存
            </el-button>
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 35px)"
            >
                >
                <el-table-column
                    label="用户名"
                >
                    <template slot-scope="scope">
                        {{ scope.row.user_name }}
                    </template>
                </el-table-column>
                <el-table-column>
                    <!-- eslint-disable-next-line vue/no-unused-vars -->
                    <template slot="header" slot-scope="scope">
                        <el-checkbox
                            :indeterminate="isIndeterminateCanEdit"
                            v-model="allCanEdit"
                            @change="checkAllCanEdit"
                        >
                            新增更新权限
                        </el-checkbox>
                    </template>
                    <template slot-scope="scope">
                        <el-checkbox
                            @change="changeEditClums($event,scope.row)"
                            name="edits"
                            :value="scope.row"
                            v-model="scope.row.can_edit"
                        ></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column
                    label="查看权限"
                >
                    <!-- eslint-disable-next-line vue/no-unused-vars -->
                    <template slot="header" slot-scope="scope">
                        <el-checkbox
                            :indeterminate="isIndeterminateCanQuery"
                            v-model="allCanQuery"
                            @change="checkAllCanQuery"
                        >
                            查看权限
                        </el-checkbox>
                    </template>
                    <template slot-scope="scope">
                        <el-checkbox
                            @change="changeQueryClums($event,scope.row)"
                            name="queries"
                            :value="scope.row"
                            v-model="scope.row.can_query"
                        ></el-checkbox>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        directoryId: {
            type: String,
        },
    },
    data() {
        return {
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 10,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: [], // 多选选中数据集合
            allCanEdit: false,
            isIndeterminateCanEdit: true,
            checkedCanEdit: [],
            allCanQuery: false,
            isIndeterminateCanQuery: true,
            checkedCanQuery: [],

        };
    },
    watch: {
        directoryId(val) {
            if (val) {
                this.directoryId = val;
                this.getUserAuth();
            }
        },
    },
    computed: {},
    methods: {
        // 获取信息项
        getUserAuth() {
            this.$axios
                .get('/interfaceApi/datacenter/interface/auth/getpage/' + this.directoryId
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                        res.rows.map(item => {
                            item.can_query = item.can_query === 1 ? true : false;
                            item.can_edit = item.can_edit === 1 ? true : false;
                        });
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存
        handleSave() {
            const data = JSON.parse(JSON.stringify(this.tableData));
            data.map(item => {
                item.can_query = Boolean(item.can_query) === true ? 1 : 0;
                item.can_edit = Boolean(item.can_edit) === true ? 1 : 0;
            });
            this.$axios
                .post('/interfaceApi/datacenter/interface/auth/save/' + this.directoryId, data)
                .then(res => {
                    if (res) {
                        this.getUserAuth();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getUserAuth();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getUserAuth();
        },
        // 多选事件
        handleSelectionChange(val) {
            this.selectData = val;
        },
        // 可查询-行复选
        changeQueryClums(event, data) {
            if (event) {
                const arr = this.checkedCanQuery.filter(item => item === data.user_id);
                if (arr.length < 1) {
                    this.checkedCanQuery.push(data.user_id);
                }

            } else {
                const index = this.checkedCanQuery.indexOf(data.user_id);
                this.checkedCanQuery.splice(index, 1);
            }
            this.allCanQuery = this.checkedCanQuery.length === this.tableData.length ? true : false;
            if (!this.allCanQuery) {
                this.isIndeterminateCanQuery = true;
            } else {
                this.isIndeterminateCanQuery = false;
            }
            this.tableData.map(item => {
                if (item.user_id === data.user_id) {
                    item.can_query = event;
                }
            });
        },
        // 可查询-全选
        checkAllCanQuery(value) {
            this.checkedCanQuery = [];
            if (value) {
                this.tableData.map(item => {
                    item.can_query = true;
                    this.checkedCanQuery.push(item.user_id);
                });
            } else {
                this.tableData.map(item => {
                    item.can_query = false;
                });
            }
            this.isIndeterminateCanQuery = false;
        },
        // 可编辑-行复选
        changeEditClums(event, data) {
            if (event) {
                const arr = this.checkedCanEdit.filter(item => item === data.user_id);
                if (arr.length < 1) {
                    this.checkedCanEdit.push(data.user_id);
                }

            } else {
                const index = this.checkedCanEdit.indexOf(data.user_id);
                this.checkedCanEdit.splice(index, 1);
            }
            this.allCanEdit = this.checkedCanEdit.length === this.tableData.length ? true : false;
            if (!this.allCanEdit) {
                this.isIndeterminateCanEdit = true;
            } else {
                this.isIndeterminateCanEdit = false;
            }
            this.tableData.map(item => {
                if (item.user_id === data.user_id) {
                    item.can_edit = event;
                }
            });
        },
        // 可编辑-全选
        checkAllCanEdit(value) {
            this.checkedCanEdit = [];
            if (value) {
                this.tableData.map(item => {
                    item.can_edit = true;
                    this.checkedCanEdit.push(item.user_id);
                });
            } else {
                this.tableData.map(item => {
                    item.can_edit = false;
                });
            }
            this.isIndeterminateCanEdit = false;
        },
    },
    created() {
        if (!this.directoryId) {
            // this.$message.warning('请先选择树节点');
            return;
        }
        this.getUserAuth();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.interfaceConfig-table
    margin-left 2.4rem
    padding .15rem
    height 100%
    background #fff
    .handle-btn
        margin-bottom .1rem
        text-align right
    .table-con
        height calc(100% - 52px)
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
</style>